import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";

import SEOImage from "../images/seo-images/home-v2.png";

import "../styles/normalize.css";
import "../styles/styles.scss";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Helmet>
        <html lang="en" />
        <link rel="icon" type="image/svg+xml" href="/favicon-BOO-2.svg" />
        <link rel="alternate-icon" href="/favicon-BOO-2.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <meta property="og:image" content={SEOImage} />
        <script
          data-no-cookie
          data-respect-dnt
          async
          src="https://cdn.splitbee.io/sb.js"
        ></script>
      </Helmet>
      <Navbar />
      <div className="content-container">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
