import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Hamburger from "../images/svg/hamburger.svg";
import Close from "../images/svg/x.svg";

const Navbar = () => {
  function showMenu() {
    document.querySelector(".mobile-menu").classList.toggle("visible");
  }
  return (
    <nav className="navbar content-container">
      {/* Mobile menu */}
      <div className="mobile-menu">
        <div className="mobile-menu-head">
          <Link to="/" className="logo-container">
            <StaticImage
              src="../images/BOO-logo.png"
              alt="Branch Out Online logo"
              placeholder="blurred"
            />
          </Link>
          <Close className="close-icon" onClick={() => showMenu()} />
        </div>
        <ul className="mobile-ul">
          <li>
            <Link to="/" className="mobile-li" onClick={() => showMenu()}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="mobile-li" onClick={() => showMenu()}>
              About
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className="mobile-li"
              onClick={() => showMenu()}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="mobile-li"
              onClick={() => showMenu()}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
      {/* ./ Mobile menu */}
      <Link to="/" className="logo">
        <StaticImage
          src="../images/BOO-logo.png"
          alt="Branch Out Online logo"
          placeholder="blurred"
        />
      </Link>
      <ul className="laptop-menu">
        <li>
          <Link to="/about" className="laptop-menu-link">
            About
          </Link>
        </li>
        <li>
          <Link to="/services" className="laptop-menu-link">
            Services
          </Link>
        </li>
        <li>
          <Link to="/contact" className="menu-btn">
            Contact
          </Link>
        </li>
      </ul>
      <Hamburger className="hamburger" onClick={() => showMenu()} />
    </nav>
  );
};

export default Navbar;
